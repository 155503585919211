import React, { useEffect, useState } from "react";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/system";

import {Grid} from "@mui/material";

import {useMediaQuery} from "@mui/material";
import BlogsCard from "./blogs-card";
import { graphql, useStaticQuery } from "gatsby";
import BlogsModel from "./blogs-model";
import syde from "../../images/sydee.png";
import Text from "../data-display/text";
import BlogSydeStory from "./BlogsydeStory";
import { isQuickTaskProject } from "../../utils/constant.urls";
import { environment } from "../../environments/quickTaskEnvironemt";

const BlogsSection = () => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const lgDown = useMediaQuery(theme.breakpoints.down("md"));

  const [activeName, setActiveName] = useState<string>("");

  const blogsData = useStaticQuery(graphql`
    {
      blogs: allBlogs {
        edges {
          node {
            author
            avatar
            content
            subTitle
            updateAt
            image
            slug
            title
          }
        }
      }
    }
  `);

  let blogs: BlogsModel[] =  blogsData?.blogs?.edges
  ?.map((el) => new BlogsModel(el.node))
  .filter(blog => blog.title && blog.author && blog.slug) ?? [];

  return (
    <>
      <Grid container style={{display:"flex",flexDirection:"row"}}>
        <div style={{}} data-scroll={true} />
        {!xsDown &&
          blogs.map((p, index) => {
            // if (j < blogs.length)
              
            if(index === 0){
              return (
                <React.Fragment key={p.slug}>
                  <div
                    style={{
                      width: "100%",
                      background: !isQuickTaskProject && `url(${syde})  no-repeat`,
                      backgroundPosition: " center",
                      backgroundSize: "cover",
                      minHeight: "90vh",
                    }}
                  >
                    {index === 0 &&<div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant={"h1"}
                        style={{color: isQuickTaskProject? environment.mainColor :  "white", fontWeight: "bold", marginTop: "80px"}}
                      >
                        News & Articls
                      </Typography>
                    </div>}

                    {/* <Text
                        variant={"h1"}
                        black
                        style={{ color: "white" }}
                      >
                        pa
                      </Text>
                      <div style={{ height: "8px" }} />
                      <Text
                        style={{
                          marginBottom: "6px",
                          maxWidth: "80%",
                          color: "white",
                        }}
                        variant={lgDown ? "body2" : "h6"}
                        regular
                      >
                        jjjjj
                      </Text> */}
                  </div>
                  <Container maxWidth="lg">
                    <BlogSydeStory
                      inView={index > 1}
                      smallMargin
                      // style={{
                      //   transform:
                      //     index === 2
                      //       ? `translateY(90px)`
                      //       : index === 1
                      //       ? "translateY(100px)"
                      //       : "",
                      // }}
                      key={p.slug}
                      title={p.title}
                      subtitle={p.subTitle}
                      image={p.image}
                      author={p.author}
                      updateAt={p.updateAt}
                      to={`/blogs/${p.slug}/`}
                    />
                  </Container>
                </React.Fragment>
              );
            }else{
              return(
                <Container maxWidth="lg" style={{marginTop: "30px",display:"flex",justifySelf:"center",justifyContent:"center",alignSelf:"center"}}>
                <div style={{display: "flex"}} key={`${index}_projectRow`}>
                <React.Fragment key={index}>
                          <BlogsCard
                            inView={index > 1}
                            smallMargin
                            style={{
                              transform:
                              index === 2
                                  ? `translateY(90px)`
                                  : index === 1
                                  ? "translateY(100px)"
                                  : "",
                            }}
                            key={index}
                            title={p.title}
                            subtitle={p.subTitle}
                            image={p.image}
                            author={p.author}
                            updateAt={p.updateAt}
                            to={`/blogs/${p.slug}/`}
                          />
                          {<div style={{width: "32px"}} />}
                        </React.Fragment>
                </div>
              </Container>
              )
            }
          })}
        <div style={{height: xsDown ? "24px" : "152px"}} data-scroll={true} />
        <div data-scroll={true} />
        {!xsDown && blogs.length % 3 !== 1 && <div style={{height: "160px"}} data-scroll={true} />}
      </Grid>
      {xsDown &&
        blogs.map((p, index) => {
          return (
            <>
              <>
                <BlogsCard
                  inView
                  width={"90%"}
                  style={{
                    marginLeft: "20px",
                  }}
                  smallMargin
                  key={index}
                  title={p.title}
                  subtitle={p.subTitle}
                  image={p.image}
                  to={`/blogs/${p.slug}/`}
                />
              </>
            </>
          );
        })}
      <div style={{height: xsDown ? "24px" : "64px"}} data-scroll={true} />
      <div style={{height: "64px"}} data-scroll={true} />
      {!xsDown && blogs.length % 3 !== 1 && <div style={{height: "160px"}} data-scroll={true} />}
    </>
  );
};

export default BlogsSection;
