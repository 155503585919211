import React, { useEffect } from "react";

import {useTheme} from "@mui/system";
import SEO from "../../components/seo/seo";
import Text from "../../components/data-display/text";
import {useMediaQuery} from "@mui/material";
import BlogsSection from "../../components/blogs/blogs-list";
import {isQuickTaskProject, title} from "../../utils/constant.urls";
  

const Blogs = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <SEO
        pathname={"/blogs/"}
        description={
          "Check out our blog page with useful information on topics such as apps, websites, business automation, e-commerce and design, written by and for our team."
        }
        title={`${title}: Technology and Business Blog`}
        keywords={["eCommerce", "News"]}
      />

      <div style={{height: xsDown ? "48px" : "65px"}} data-scroll={true} />
      <BlogsSection />
    </>
  );
};

export default Blogs;
